import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "deleting-is-a-destructive-action-to-protect-user-resources-your-product-should-support-undo-where-possible-if-undo-support-is-impossible-request-user-confirmation-before-deleting-a-resource-or-asset"
    }}>{`Deleting is a destructive action. To protect user resources, your product should support ‘undo’ where possible. If ‘undo’ support is impossible, request user confirmation before deleting a resource or asset.`}</h3>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p>{`Experimental`}</p>
    <h4 {...{
      "id": "maintainers"
    }}>{`Maintainers:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.ibm.com/VICTORIA"
      }}>{`Vikki Paterson`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.ibm.com/Arnaud-Gillard"
      }}>{`Arnaud Gillard`}</a></p>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">High impact</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Medium impact</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Low impact</AnchorLink>
    </AnchorLinks>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.69021739130435%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a deletion pattern using a modal in context",
        "title": "Example of a deletion pattern using a modal in context",
        "src": "/static/9b63ff7d1f20d723fe869ba80f21769a/fb070/1.png",
        "srcSet": ["/static/9b63ff7d1f20d723fe869ba80f21769a/d6747/1.png 288w", "/static/9b63ff7d1f20d723fe869ba80f21769a/09548/1.png 576w", "/static/9b63ff7d1f20d723fe869ba80f21769a/fb070/1.png 1152w", "/static/9b63ff7d1f20d723fe869ba80f21769a/c3e47/1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "high-impact"
    }}>{`High impact`}</h2>
    <p>{`A high-impact deletion cannot be reversed. The action would result in a significant loss for a user if done accidentally.`}</p>
    <p>{`For high-impact scenarios, a user should confirm the action by manually entering the name of the resource. The ‘Delete’ button is enabled when the text entered perfectly matches the resource name.`}</p>
    <p>{`The text should tell the user the consequences of the deletion and that the action cannot be undone. Optionally, when the delete button is selected it changes to `}<inlineCode parentName="p">{`Deleting…`}</inlineCode>{`.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "60.733695652173914%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of an un-populated high-impact deletion modal",
        "title": "Example of an un-populated high-impact deletion modal",
        "src": "/static/f4b896f8024e27a428d7100d0bc09640/fb070/2.png",
        "srcSet": ["/static/f4b896f8024e27a428d7100d0bc09640/d6747/2.png 288w", "/static/f4b896f8024e27a428d7100d0bc09640/09548/2.png 576w", "/static/f4b896f8024e27a428d7100d0bc09640/fb070/2.png 1152w", "/static/f4b896f8024e27a428d7100d0bc09640/c3e47/2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "60.733695652173914%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a populated high-impact deletion modal",
        "title": "Example of a populated high-impact deletion modal",
        "src": "/static/20f289e1f4a480c64c8fcfc1f08b7b4c/fb070/3.png",
        "srcSet": ["/static/20f289e1f4a480c64c8fcfc1f08b7b4c/d6747/3.png 288w", "/static/20f289e1f4a480c64c8fcfc1f08b7b4c/09548/3.png 576w", "/static/20f289e1f4a480c64c8fcfc1f08b7b4c/fb070/3.png 1152w", "/static/20f289e1f4a480c64c8fcfc1f08b7b4c/c3e47/3.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "60.733695652173914%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a high-impact deletion modal in its \"deleting\" state",
        "title": "Example of a high-impact deletion modal in its \"deleting\" state",
        "src": "/static/93638eca3143888b9c8c9ef99ca9b87a/fb070/5_0.png",
        "srcSet": ["/static/93638eca3143888b9c8c9ef99ca9b87a/d6747/5_0.png 288w", "/static/93638eca3143888b9c8c9ef99ca9b87a/09548/5_0.png 576w", "/static/93638eca3143888b9c8c9ef99ca9b87a/fb070/5_0.png 1152w", "/static/93638eca3143888b9c8c9ef99ca9b87a/c3e47/5_0.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "60.733695652173914%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a high-impact deletion modal in its \"deleting\" state",
        "title": "Example of a high-impact deletion modal in its \"deleting\" state",
        "src": "/static/da201b400a72ac2e3c76ee5c4ef6f648/fb070/5_1.png",
        "srcSet": ["/static/da201b400a72ac2e3c76ee5c4ef6f648/d6747/5_1.png 288w", "/static/da201b400a72ac2e3c76ee5c4ef6f648/09548/5_1.png 576w", "/static/da201b400a72ac2e3c76ee5c4ef6f648/fb070/5_1.png 1152w", "/static/da201b400a72ac2e3c76ee5c4ef6f648/c3e47/5_1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p>{`You can choose to show a notification confirming deletion is completed. This is useful when the deletion of the resource takes more than a few moments.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "37.5%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a successful delete notification",
        "title": "Example of a successful delete notification",
        "src": "/static/8121e140e2540269b43a9c4f75be7071/fb070/5_2.png",
        "srcSet": ["/static/8121e140e2540269b43a9c4f75be7071/d6747/5_2.png 288w", "/static/8121e140e2540269b43a9c4f75be7071/09548/5_2.png 576w", "/static/8121e140e2540269b43a9c4f75be7071/fb070/5_2.png 1152w", "/static/8121e140e2540269b43a9c4f75be7071/c3e47/5_2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "medium-impact"
    }}>{`Medium impact`}</h2>
    <p>{`A medium-impact deletion is one that cannot be reversed, but would not be catastrophic if done accidentally. When deleting is medium-impact, a confirmation dialog should be presented to the user which displays:`}</p>
    <ul>
      <li parentName="ul">{`The name of the resource`}</li>
      <li parentName="ul">{`Consequences of the deletion`}</li>
      <li parentName="ul">{`The action cannot be undone`}</li>
    </ul>
    <p>{`Optionally, when the delete button is selected it changes to `}<inlineCode parentName="p">{`Deleting…`}</inlineCode>{`.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.66847826086957%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a standard delete modal",
        "title": "Example of a standard delete modal",
        "src": "/static/8eabc55d88798fec2ccc231962512f7a/fb070/6.png",
        "srcSet": ["/static/8eabc55d88798fec2ccc231962512f7a/d6747/6.png 288w", "/static/8eabc55d88798fec2ccc231962512f7a/09548/6.png 576w", "/static/8eabc55d88798fec2ccc231962512f7a/fb070/6.png 1152w", "/static/8eabc55d88798fec2ccc231962512f7a/c3e47/6.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.66847826086957%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a standard delete modal in its \"deleting\" state",
        "title": "Example of a standard delete modal in its \"deleting\" state",
        "src": "/static/ba77e6755749bc30e73c1b839b3fdada/fb070/1_1.png",
        "srcSet": ["/static/ba77e6755749bc30e73c1b839b3fdada/d6747/1_1.png 288w", "/static/ba77e6755749bc30e73c1b839b3fdada/09548/1_1.png 576w", "/static/ba77e6755749bc30e73c1b839b3fdada/fb070/1_1.png 1152w", "/static/ba77e6755749bc30e73c1b839b3fdada/c3e47/1_1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.66847826086957%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a standard delete modal in its \"deleting\" state",
        "title": "Example of a standard delete modal in its \"deleting\" state",
        "src": "/static/9f4a6d190a2b4458f6bbc42e8bb9e750/fb070/1_2.png",
        "srcSet": ["/static/9f4a6d190a2b4458f6bbc42e8bb9e750/d6747/1_2.png 288w", "/static/9f4a6d190a2b4458f6bbc42e8bb9e750/09548/1_2.png 576w", "/static/9f4a6d190a2b4458f6bbc42e8bb9e750/fb070/1_2.png 1152w", "/static/9f4a6d190a2b4458f6bbc42e8bb9e750/c3e47/1_2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p>{`You can choose to show a notification confirming deletion is completed. This is useful when the deletion of the resource takes more than a few moments.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "37.36413043478261%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a successful delete notification",
        "title": "Example of a successful delete notification",
        "src": "/static/9b282ba0b337dd9384021e4221004ad8/fb070/7_updated.png",
        "srcSet": ["/static/9b282ba0b337dd9384021e4221004ad8/d6747/7_updated.png 288w", "/static/9b282ba0b337dd9384021e4221004ad8/09548/7_updated.png 576w", "/static/9b282ba0b337dd9384021e4221004ad8/fb070/7_updated.png 1152w", "/static/9b282ba0b337dd9384021e4221004ad8/c3e47/7_updated.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "low-impact"
    }}>{`Low impact`}</h2>
    <p>{`Requiring the user to confirm deletion is generally recommended. However, in very low impact situations, such as when an ‘undo’ option is available, user confirmation may not be required.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      